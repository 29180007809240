import React, { useState } from 'react';
import { Tooltip, Icon } from 'antd';
import copyToClipboard from 'utils/copyToClipboard';
import intl from 'react-intl-universal';
import styles from './CopyIcon.less';

const CopyIcon = ({ className, text }) => {
  const [copySuccess, setCopySuccess] = useState('');

  const handleCopy = (e) => {
    let timer;
    if (timer) {
      clearTimeout(timer);
    }
    copyToClipboard(text);
    e.target.focus();
    setCopySuccess(intl.get('bargain.copied'));
    timer = setTimeout(() => {
      setCopySuccess('');
    }, 1000);
  };

  return (
    <span className={className}>
      <Tooltip title={intl.get('bargain.link_copy')}>
        <Icon
          type={!copySuccess ? 'copy' : 'check'}
          style={{
            color: !copySuccess ? '#b5b5b5' : '#5fc973',
            fontSize: 18
          }}
          onClick={handleCopy}
        />
      </Tooltip>
    </span>
  );
};

export default CopyIcon;
