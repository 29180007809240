import { Popover, Table, Modal, Icon, Tooltip, message } from 'antd';
import Ellipsis from 'components/Ellipsis';
import React,{useState} from 'react';
import { connect } from 'dva'
import intl from 'react-intl-universal';
import { routerRedux } from 'dva/router';

import store from '../../../index';
import selfIcon from '../../../assets/supplier_optimizer/selfIcon.svg';
import { replaceUrlToHttps } from 'utils/utils';
import { addImageSizeParams, createAliItemUrl } from '../util';
import { LoadingButton } from './Buttons';
import { Post } from 'utils/request';
import styles from './SimilarProductTable.less';
import { emitCustomEvent } from 'utils/dsersCustomEvent';
import { importButtonClickEvent, sendGoodsClickEvent } from 'routes/Bargain/util';
import { sendNoviceGuidanceInfo } from 'utils/utils';
import CopyIcon from './CopyIcon';
import { GuildeAction, LinkStoreAction } from 'actions';

import { CommonDataCollect } from 'utils/utils';

const { dispatch } = store;

function SimilarProductTable({ products, dispatch, hidden, noviceGuidanceInfo, currencyState, searchType }) {

  const [isUse,setIsUse] = useState(false)

  const importToDsers = async (product, closeLoading) => {
    
    const { id } = product;
    const { user_id } = JSON.parse(localStorage.getItem('store'));
  
    CommonDataCollect({
      user_id: user_id,
      sale_platform: 'shopify',
      supply_platform: 'aliexpress',
      supply_platform_product_id: id,
      trans_from: 'add_to_import_list_supplier_optimizer',
      action: 'add_to_import_list',
      event_type: 'subscription_analysis'
    });
  
    
    try {
      setIsUse(true)
      const res = await Post('/prod/select', {
        data: {
          url: createAliItemUrl(product?.id),
          scene: 'dsers_page', // dsers_page || extension
          ref: 'similar', // bargain || similar
          token: product?.token,
        }
      });
      if(res.code == 4290 && res.msg == 'The quantity of products you imported to DSers has reached the "Product limit" of your current subscription plan. Please upgrade your subscription to import more products.'){
        // window.open('/app/pricing')
        window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'ProductsLimit')
      }
      if (res.code === 2000) {
        // 上报已经导过商品
        if(noviceGuidanceInfo.imported == 0){
          sendNoviceGuidanceInfo({imported : 1},()=>{
            routerRedux.push({ type:'user/getNoviceGuidance'})
          })
        }
        dispatch({
          type: 'topBtn/notificationUnreadLength',
        });
        Modal.confirm({
          title: intl.get('bargain.m_title'),
          icon: null,
          content: intl.get('bargain.m_con'),
          okText: intl.get('bargain.m_btn_go'),
          cancelText: intl.get('bargain.m_btn_stay'),
          onOk: () => {
            window.location.href = '/app/storemanage/import';
            return Promise.resolve();
          }
        });
      }
      setIsUse(false)
    } catch (e) {
      console.log(e);
      setIsUse(false)
    }
    if(closeLoading && typeof closeLoading === 'function'){
      closeLoading();
    }
  };

  const importToDsers_tmall = async (product) => {
    const params = {
      supplier_type:4,
      product_id:product.productId
    };
    try {
      setIsUse(true)
      const res = await Post('/prod/select', { data: params });
      if(res.code == 4290 && res.msg == 'The quantity of products you imported to DSers has reached the "Product limit" of your current subscription plan. Please upgrade your subscription to import more products.'){
        window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'ProductsLimit')
      }
      if (res.code === 2000 && res.data) {
        
        dispatch({
          type: 'topBtn/notificationUnreadLength',
          payload: {
            cancelMessage: true,
          }
        });
        Modal.confirm({
          title: intl.get('bargain.m_title'),
          icon: null,
          content: intl.get('bargain.m_con'),
          okText: intl.get('bargain.m_btn_go'),
          cancelText: intl.get('bargain.m_btn_stay'),
          onOk: () => {
            window.location.href = '/app/storemanage/import';
            return Promise.resolve();
          }
        });
      } 
      setIsUse(false)
    } catch (error) {
      console.log(e);
      setIsUse(false)
    }
  }
  
  const getScorllBody = () => document.getElementsByClassName('ant-table-body');
  
  const scrollToLeft = () => {
    const ele = getScorllBody()[0];
    if (ele) {
      // const currentLeft = ele.scrollLeft;
      ele.scrollTo({
        left: 0,
        behavior: 'smooth'
      });
    }
    // console.log(ele);
  };
  
  const scrollToRight = () => {
    const ele = getScorllBody()[0];
    if (ele) {
      // const currentLeft = ele.scrollLeft;
      ele.scrollTo({
        left: 174,
        behavior: 'smooth'
      });
    }
  };
  
  const createProductData = record => {
    return {
      om_label: {
        supplier: 'aliexpress',
        partition: 'search_by_img'
      },
      id: record.id,
      name: record.title,
      category_lv1: ''
    };
  };

  const createGoTmallUrl = (id)=>{
    return `/app/tmallproduct/${id}?appId=159831084`
  }

  let dataColumns = []
  
  searchType.current === 'Tmall' ? 
  dataColumns = [
    {
      title: intl.get('supplier_optimizer.product_title'),
      dataIndex: 'mainImgUrl',
      key: 'image',
      align: 'left',
      render: (image, record, index) => {
        // const imgId = `small_img_${record.id}`;
        const clipedImg = addImageSizeParams(image || '');
        const clickHandler = () => {
          const productData = createProductData(record);
          const params = sendGoodsClickEvent(productData, index);
          emitCustomEvent({
            page: 1,
            page_size: 60,
            ...params
          });
        };
        return (
            <div style={{ position: 'relative' }}>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={createGoTmallUrl(record.productId)}
                className={styles.titleImgContainer}
                // onClick={clickHandler}
              >
              <Popover
                // getPopupContainer={() => getImgPopupContainer(imgId)}
                content={
                  <img height="220px" width="220px" src={replaceUrlToHttps(image)} />
                }
              >
                <img height="64px" width="64px" src={clipedImg} />
              </Popover>
                <Ellipsis className={styles.ellipsisCon} style={{ width: '160px' }} title={record.title} lines={4}>
                  {record.title}
                </Ellipsis>
              </a>
              <CopyIcon className={styles.copy} text={`https://www.dsers.com${createGoTmallUrl(record.productId)}`} />
            </div>
        );
      },
      width: 400
    },
    {
      title: intl.get('supplier_optimizer.price'),
      dataIndex: 'maxPrice',
      render: (price, record) => {
        if(price){
          const currency_price = (Number(price) * 0.01).toFixed(2)
          return <div className={styles.tmallPricing}>
            <span>{`US $${currency_price}`}</span>
            <Tooltip title={intl.get('suppliers.importBtn')}>
            <div
              style={{backgroundColor:isUse ? '#dde0e2' : null}}
              className={styles.ratingImport}
              onClick={isUse ? null : () => {
                importToDsers_tmall(record);
              }}>
                <i class="material-icons">list_alt</i>
            </div>
           </Tooltip>
          </div>
        }else{
          return '-'
        }
      },
      key: 'price',
      width: 60,
      sorter: (item1, item2) => (Number(item1.maxPrice) * 0.01) - (Number(item2.maxPrice) * 0.01)
    },
  ]  :  
  dataColumns = [
    {
      title: intl.get('supplier_optimizer.product_title'),
      dataIndex: 'image',
      key: 'image',
      align: 'left',
      render: (image, record, index) => {
        // const imgId = `small_img_${record.id}`;
        const clipedImg = addImageSizeParams(image || '');
        const clickHandler = () => {
          const productData = createProductData(record);
          const params = sendGoodsClickEvent(productData, index);
          emitCustomEvent({
            page: 1,
            page_size: 60,
            ...params
          });
        };
        return (
            <div style={{ position: 'relative' }}>
              <a
                // id={imgId}
                rel="noopener noreferrer"
                target="_blank"
                href={createAliItemUrl(record.id)}
                className={styles.titleImgContainer}
                onClick={clickHandler}
              // style={{ position: 'relative' }}
              >
              <Popover
                // getPopupContainer={() => getImgPopupContainer(imgId)}
                content={
                  <img height="220px" width="220px" src={replaceUrlToHttps(image)} />
                }
              >
                <img height="64px" width="64px" src={clipedImg} />
              </Popover>
                {/* <span className={styles.secNum}>{index + 1}</span> */}
                <Ellipsis className={styles.ellipsisCon} style={{ width: '160px' }} title={record.title} lines={4}>
                  <Tooltip title={intl.get('supplier_optimizer.searched_product')}>
                    {record.isSelf ? (
                      <img className={styles.selfIcon} src={selfIcon} />
                    ) : null}
                  </Tooltip>
                  {record.title}
                </Ellipsis>
              </a>
              <CopyIcon className={styles.copy} text={createAliItemUrl(record.id)} />
            </div>
        );
      },
      width: 200
    },
    {
      title: intl.get('supplier_optimizer.price'),
      dataIndex: 'price.format_amount',
      render: (format, record) => {
        if(record.price?.value){
          const currency_price = (Number(record.price?.value) / currencyState[record.price?.currency]?.rates_value).toFixed(2)
          return `US $${currency_price}`
        }
        return record.price?.value ? format : '-';
      },
      key: 'price',
      width: 60,
      sorter: (item1, item2) => item1.price?.value - item2.price?.value
    },
    {
      title: intl.get('supplier_optimizer.shipping_price'),
      dataIndex: 'ship_price',
      key: 'ship_price',
      sorter: (item1, item2) => {
        const value1 =
          item1.sortShipPrice === undefined ? Infinity : item1.sortShipPrice;
        const value2 =
          item2.sortShipPrice === undefined ? Infinity : item2.sortShipPrice;
        return value1 - value2;
      },
      width: 105,
      render: shipPrice => {
        console.log(products,'------products------');
        return <span>{shipPrice || '-'}</span>;
      }
    },
    {
      title: intl.get('supplier_optimizer.sale_number'),
      dataIndex: 'trade_count',
      key: 'order',
      sorter: (item1, item2) => item1.trade_count - item2.trade_count,
      width: 100,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: intl.get('supplier_optimizer.delivery'),
      dataIndex: 'ship_time',
      key: 'ship_time',
      width: 105,
      render: shipTime => {
        console.log(shipTime, 'shipTime');
        const shipText = shipTime || '-';
        return <span>{shipText}</span>;
      },
      sorter: (item1, item2) =>
        (item1.sortTime || Infinity) - (item2.sortTime || Infinity)
    },
    {
      title: intl.get('bargain.product_rating'),
      dataIndex: 'rating',
      key: 'productRating',
      align: 'center',
      render: (rating, record, index) => {
        return <div className={styles.rating}>
          <i class="material-icons">star</i>{rating || '--'}
          <Tooltip title={intl.get('suppliers.importBtn')}>
            <div
              style={{backgroundColor:isUse ? '#dde0e2' : null}}
              className={styles.ratingImport}
              onClick={isUse ? null : closeLoading => {
                const productData = createProductData(record);
                const params = importButtonClickEvent(productData, index);
                emitCustomEvent({
                  page: 1,
                  page_size: 60,
                  ...params
                });
                importToDsers(record, closeLoading);
              }}>
                <i class="material-icons">list_alt</i>
            </div>
           </Tooltip>
        </div>;
      },
      sorter: (item1, item2) =>
        (parseFloat(item1.rating) || 0) - (parseFloat(item2.rating) || 0),
      width: 84,
      sortDirections: ['descend', 'ascend']
    }
  ];

  return (
    <Table
      className={`${styles.productTable} ${hidden ? styles.hidden : ''}`}
      bordered={false}
      columns={dataColumns}
      dataSource={products}
      pagination={false}
      rowKey={record => record.id}
      scroll={{
        x: 868,
        y: false,
        scrollToFirstRowOnChange: true
      }}
    />
  );
}

// export default React.memo(SimilarProductTable);
export default connect(({ user, global }) => ({
  noviceGuidanceInfo:user?.noviceGuidanceInfo,
  currencyState:global?.currencyState
}))(SimilarProductTable)