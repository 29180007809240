import React, { useMemo, useState, useEffect, useRef } from 'react';
import intl from 'react-intl-universal';
import styles from './ProcessBar.less';

export default function ProcessBar({ loading }) {
  const [stage, setStage] = useState(0);
  const [processWidth, setProcessWidth] = useState(0);
  const initRef = useRef(false);
  const processRef = useRef(0);
  useEffect(() => {
    let interval = null;
    if (loading) {
      initRef.current = true;
      setStage(1);
      setProcessWidth(0);
      processRef.current = 0;
      const runProcess = () => {
        const newWidth = processRef.current + 50;
        if (newWidth >= 600) return;
        if (processRef.current % 200 === 0) {
          setStage(Math.floor(processRef.current / 200) + 1);
        }
        processRef.current = newWidth;
        setProcessWidth(newWidth);
      };
      interval = setInterval(() => {
        runProcess();
      }, 500);
    } else if (initRef.current) {
      const runProcess = () => {
        const newWidth = processRef.current + 200;
        if (newWidth >= 800) {
          setStage(0);
          processRef.current = 0;
          setProcessWidth(0);
          clearInterval(interval);
          return false;
        }
        setStage(Math.floor(processRef.current / 200) + 1);
        processRef.current = newWidth;
        setProcessWidth(newWidth);
      };
      const needAdd = processRef.current % 200 !== 0 &&
        200 * (Math.floor(processRef.current / 200) + 1) - processRef.current;

      if (needAdd) {
        processRef.current += needAdd;
        setProcessWidth(processRef.current);
      } else if (processRef.current >= 600) {
        setStage(0);
        processRef.current = 0;
        setProcessWidth(0);
        clearInterval(interval);
        return () => {};
      }
      interval = setInterval(() => {
        runProcess();
      }, 200);
    }
    return () => {
      initRef.current = true;
      clearInterval(interval);
    };
  }, [loading]);
  return (
    (loading || stage !== 0) && (
      <div className={styles.loadItem}>
        <div className={styles.loadBox}>
          <div className={styles.iconContainer}>
            <span className={styles.load} />
            <span className={styles[`icon${stage}`]} />
          </div>
          <div className={styles.processbar}>
            <div className={styles.border}>
              <div className={styles.bar} style={{ width: processWidth / 3 }} />
              <div className={styles.desc}>
                {intl.get(`supplier_optimizer.loading_state_${stage}`)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
