import { Input, Popover, Button, Select } from 'antd';
import React, { useMemo, useState, useEffect, useRef } from 'react';
import { unstable_batchedUpdates } from 'react-dom'
import intl from 'react-intl-universal';

import { CameraButton } from './Buttons';
import styles from './SearchInput.less';
import searchImg from '../../../assets/svg/search.svg';
import useOnlineNotice from 'hooks/useOnlineNotice';
import { getBaseLayoutPopupContainer } from 'utils/utils';

const onlineDate = new Date(2020, 10, 19);
const { Option } = Select

function SearchInput({
  value,
  handleInputChange,
  openUploadModal,
  onSearch,
  showSearchTip,
  showGuideButton,
  show,
  changeShowSearchStatus,
  selectType,
  handleSelectChange,
  onSearchTmall,
  onOk
}) {
  const [visivle, close] = useOnlineNotice(
    'supplier_optimizer_search_tip',
    onlineDate
  );

  const domRef = useRef();

  const [eventInDownStatus,setEventInDownStatus] = useState()
  const [eventOutDownStatus,setEventOutDownStatus] = useState()
  const [eventInUpStatus,setEventInUpStatus] = useState()
  const [eventOutUpStatus,setEventOutUpStatus] = useState()

  const options = window.TMALL_USER ? [
    {
      value:"AliExpress",
      label:'AliExpress'
    },
    {
      value:"Tmall",
      label:"Tmall"
    }
  ] : [{value:"AliExpress",label:'AliExpress'}]

  // useEffect(()=>{
  //   if(eventInDownStatus === true && eventInUpStatus === true || eventInDownStatus === true && eventOutUpStatus === false){
  //     unstable_batchedUpdates(()=>{
  //       setEventInDownStatus()
  //       setEventOutDownStatus()
  //       setEventInUpStatus()
  //       setEventOutUpStatus()
  //     })
  //     return
  //   }else if(eventOutDownStatus === true && eventOutUpStatus === true){
  //     changeShowSearchStatus();
  //     unstable_batchedUpdates(()=>{
  //       setEventInDownStatus()
  //       setEventOutDownStatus()
  //       setEventInUpStatus()
  //       setEventOutUpStatus()
  //     })
  //   }
  // },[eventInDownStatus,eventOutDownStatus,eventInUpStatus,eventOutUpStatus])

  // useEffect(() => {
  //   const handleMouseDown = e => {
  //     if (domRef.current?.contains(e.target)) {
  //       setEventInDownStatus(true)
  //       return;
  //     }
  //     setEventOutDownStatus(true)
  //   };
  //   const handleMouseUp = e => {
  //     if (domRef.current?.contains(e.target)) {
  //       setEventInUpStatus(true)
  //       return;
  //     }
  //     setEventOutUpStatus(true)
  //   };
  //   document.addEventListener('mousedown', handleMouseDown,false);
  //   document.addEventListener('mouseup', handleMouseUp,false);
  //   return () => {
  //     document.addEventListener('mousedown', handleMouseDown,false);
  //     document.addEventListener('mouseup', handleMouseUp,false);
  //   };
  // }, []);

  const searchTipVisible = useMemo(() => {
    return showSearchTip && visivle;
  }, [showSearchTip, visivle]);

  const popoverClickHandler = e => {
    e.stopPropagation();
    return false;
  };

  const popoverContent = (
    <div onClick={popoverClickHandler} role="presentation">
      <div>{intl.get('supplier_optimizer.search_tip')}</div>
      <div
        role="button"
        tabIndex="-1"
        className={styles.dontShowAgain}
        onClick={close}
      >
        {intl.get('bargain.dont_show_again')}
      </div>
    </div>
  );

  return (
    <div id="supplier-optimizer-search-input" ref={domRef} >
      <Input
        placeholder={intl.get('supplier_optimizer.placeholder')}
        allowClear
        size="large"
        className={styles.searchInput}
        value={value}
        // ref={(input)=>{
        //   if (input != null) {
        //     input.focus({
        //         cursor: 'end'
        //     });
        //   } 
        // }}
        onChange={handleInputChange}
        suffix={
          <i class="material-icons notranslate"
            onClick={() => {
              handleInputChange('');
            }}
            style={{ fontSize: '20px', cursor: 'pointer', position: 'relative', left: '-55px'}} >cancel</i>
        }
        addonBefore={
          <div className={styles.addonBeforeBox}>
              <Select value={selectType} onChange={(v)=>{handleSelectChange(v)}}>
                  {
                    options.map(item=>{
                      return <Option value={item.value} >{item.label}</Option>
                    })
                  }
              </Select>
              <div className={styles.line}></div>
              <CameraButton onClick={openUploadModal} className={styles.beforecameraButton}/>
            </div>
        }
      />
      <Popover
        getPopupContainer={getBaseLayoutPopupContainer}
        visible={searchTipVisible}
        content={popoverContent}
        overlayClassName={styles.searchTipOverlay}
      >
        {showGuideButton ? (
          <span style={{ fontSize: 12 }}>{intl.get('supplier_optimizer.have_try')}</span>
        ) : (
          <Button className={styles.popoverBtn} onClick={() => {
            selectType === 'Tmall' ?  onSearchTmall(value) : onSearch(value)
            changeShowSearchStatus()
            window.dtag.set({
              event:'click',
              module: selectType === 'Tmall' ? 'tmall' : 'AliExpress',
              action: selectType === 'Tmall' ? 'tmall_figure_search': 'AliExpress_figure_search',
              custom_data: selectType === 'Tmall'? {'click_type': 'go_supplier_importlist_Tmall'} : {'click_type': 'go_supplier_importlist_AliExpress'}
            })
          }}>
            <img
              width="16"
              height="16"
              style={{ verticalAlign: 'baseline', marginTop: 4 }}
              src={searchImg}
            />
          </Button>
        )}
      </Popover>
    </div>
  );
}

export default React.memo(SearchInput);
