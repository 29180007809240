import React, { useState, useEffect } from 'react';
import { Checkbox, Select, Tooltip } from 'antd';
import intl from 'react-intl-universal';

import styles from './ShippingSelect.less';
import '../index.css'
import { Get } from 'utils/request';
import { getBaseLayoutPopupContainer } from 'utils/utils';
import { FlowRight } from 'lodash-decorators';

// const getPopupContainer = () => {
//   return (
//     document.getElementById('supplier-optimizer-ship-select') || document.body
//   );
// };

function ShippingSelect({
  shipFromChange,
  checked,
  shipToChange,
  onCheck,
  shipMethods,
  shipMethodChange,
  hidden
}) {
  const [countries, setCountries] = useState([]);
  const countriesWithCN = [
    {
      Name: 'China',
      Code: 'CN'
    },
    ...countries
  ];
  useEffect(() => {
    const getCountries = async () => {
      const res = await Get('/set/country');
      // console.log(res);
      setCountries(res?.data || []);
    };
    getCountries();
  }, []);

  const handleCheckBoxChange = e => {
    onCheck(e.target.checked);
  };

  return (
    <div
      className={`${styles.container} ${hidden ? styles.hidden : ''}`}
      id="supplier-optimizer-ship-select"
    >
      <Tooltip overlay={intl.get('supplier_optimizer.ship_tip')}>
        <Checkbox
          className={styles.checkBox}
          onChange={handleCheckBoxChange}
          checked={checked}
        >
          {intl.get('supplier_optimizer.delivery_info')}
        </Checkbox>
      </Tooltip>
      <div>
        <Select
          placeholder={intl.get('supplier_optimizer.ship_from')}
          showSearch
          className={styles.utc}
          optionFilterProp="children"
          onChange={shipFromChange}
          dropdownMatchSelectWidth={false}
          disabled={!checked}
          // allowClear
          defaultValue="CN"
          suffixIcon={
            <img
              className="select_icon"
              src={require('../../../assets/newImportList/icon_expand_more-24px.png')}
            />
          }
          getPopupContainer={getBaseLayoutPopupContainer}
        >
          {checked ? (
            countriesWithCN.map(item => {
              return (
                <Select.Option key={item.Code} value={item.Code}>
                  {item.Name}
                </Select.Option>
              );
            })
          ) : (
            <Select.Option value="CN">
              {intl.get('supplier_optimizer.ship_from')}
            </Select.Option>
          )}
        </Select>
        <span style={{ marginRight: '10px' }}>|</span>
        <Select
          placeholder={intl.get('supplier_optimizer.ship_to')}
          style={{ width: '120px' }}
          className={styles.utc}
          showSearch
          dropdownMatchSelectWidth={false}
          optionFilterProp="children"
          onChange={shipToChange}
          disabled={!checked}
          // allowClear
          suffixIcon={
            <img
              className="select_icon"
              src={require('../../../assets/newImportList/icon_expand_more-24px.png')}
            />
          }
          defaultValue="US"
          getPopupContainer={getBaseLayoutPopupContainer}
        >
          {checked ? (
            countries.map(item => {
              return (
                <Select.Option key={item.Code} value={item.Code}>
                  {item.Name}
                </Select.Option>
              );
            })
          ) : (
            <Select.Option value="US">
              {intl.get('supplier_optimizer.ship_to')}
            </Select.Option>
          )}
        </Select>
        <span style={{ marginRight: '10px' }}>|</span>
        <Select
          placeholder={intl.get('supplier_optimizer.ship_from')}
          style={{ width: 150 }}
          showSearch
          className={styles.utc}
          optionFilterProp="children"
          dropdownMatchSelectWidth={false}
          onChange={shipMethodChange}
          disabled={!checked}
          suffixIcon={
            <img
              className="select_icon"
              src={require('../../../assets/newImportList/icon_expand_more-24px.png')}
            />
          }
          defaultValue="AliExpress Standard Shipping"
          getPopupContainer={getBaseLayoutPopupContainer}
        >
          {checked ? (
            shipMethods.map(item => {
              return (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              );
            })
          ) : (
            <Select.Option value="AliExpress Standard Shipping">
              {intl.get('supplier_optimizer.ship_method')}
            </Select.Option>
          )}
        </Select>
      </div>

    </div>
  );
}

export default React.memo(ShippingSelect);
