import React from 'react';
import { Table, Button } from 'antd';
import intl from 'react-intl-universal';
import { list_V5 } from 'utils/bannerWhiteList';

import styles from './BannerTable.less';
import adIcon from '../../../assets/bargain/ad.png';
import adBetter from '../../../assets/bargain/ad_better.png';

function BannerTable({ bannerList, handleBannerClick, userId }) {
  const isV5 = list_V5.includes(userId);

  const dataColumns = [
    // {
    //   title: 'Product Name',
    //   dataIndex: 'dsers_img',
    //   key: 'dsers_img',
    //   fixed: 'left',
    //   render: (image, record) => {
    //     const img = image.split(';');
    //     return (
    //       <a
    //         rel="noopener noreferrer"
    //         target="_blank"
    //         href={record.dsers_url}
    //         className={styles.titleImgContainer}
    //       >
    //         <img className={styles.titleImg} src={img[0]} />
    //       </a>
    //     );
    //   }
    // },
    // {
    //   title: '',
    //   dataIndex: 'dsers_product_title',
    //   key: 'dsers_product_title',
    //   render: (title, record) => {
    //     return (
    //       <a
    //         rel="noopener noreferrer"
    //         target="_blank"
    //         href={record.dsers_url}
    //         className={styles.title}
    //       >
    //         <img className={styles.adIcon} src={adIcon} />
    //         {title}
    //       </a>
    //     );
    //   },
    //   width: 570
    // },
    {
      title: 'Product Name',
      dataIndex: 'ae_img',
      key: 'ae_img',
      fixed: 'left',
      render: (image, record) => {
        const img = image.split(';');
        return (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={record.dsers_url}
            className={styles.titleImgContainer}
          >
            <img className={styles.titleImg} src={img[0]} />
          </a>
        );
      }
    },
    {
      title: '',
      dataIndex: 'ae_product_title',
      key: 'ae_product_title',
      render: (title, record) => {
        return (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={record.ae_url}
            className={styles.title}
          >
            <img className={styles.adIcon} src={adIcon} />
            {title}
          </a>
        );
      },
      width: 570
    },
    {
      title: 'Special offer',
      dataIndex: 'dsers_price',
      key: 'dsers_price',
      render: dsersPrice => (
        <span className={styles.dsersPrice}>
          <img className={styles.betterIcon} src={adBetter} />
          {dsersPrice ? (
            <span>
              {dsersPrice}
              <span style={{ fontSize: 14 }}>$</span>
            </span>
          ) : (
            <span>-</span>
          )}
        </span>
      ),
      width: 300
    },
    {
      title: 'Current price',
      dataIndex: 'ae_price',
      key: 'ae_price',
      render: aePrice => (
        <span className={styles.currentPrice}>
          {aePrice ? (
            <span>
              {aePrice}
              <span style={{ fontSize: 14 }}>$</span>
            </span>
          ) : (
            <span>-</span>
          )}
        </span>
      ),
      width: 300
    },
    {
      key: 'store_id',
      render: (id, record) => {
        return (
          <Button
            className={styles.cheaperButton}
            onClick={() => handleBannerClick(record)}
          >
            {/* {intl.get('bargain.reward')} */}
            {isV5 ? 'up to 20% off' : intl.get('bargain.found_cheaper')}
          </Button>
        );
      },
      width: 157,
      fixed: 'right',
      align: 'center'
    }
  ];
  return (
    <Table
      className={styles.bannerTable}
      bordered={false}
      columns={dataColumns}
      dataSource={bannerList}
      pagination={false}
      rowKey={record => record.ae_product_id}
    />
  );
}

export default React.memo(BannerTable);
