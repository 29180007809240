import { Spin, message, Button } from 'antd';
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef
} from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'dva/router';
import qs from 'querystring';
import { connect } from 'dva';
import { getLanguage, CommonDataCollect } from 'utils/utils';
import ScrollControl from 'components/ScrollControl';
import { Get, Post } from 'utils/request';
import {dsersBFFApiHost} from 'common/sysconfig'
import { getVersion } from 'utils/bannerWhiteList';
import InputGuide from 'components/FilterDrawer/InputGuide'

import UploadImageModal from './components/UploadImageModal';
import styles from './index.less';
import './index.css';
import ShippingSelect from './components/ShippingSelect';
import BannerTable from './components/BannerTable';
import findProductImg from '../../assets/supplier_optimizer/optimizerBanner.png';
import emptyImg from '../../assets/bargain/empty_img.png';
import searchIcon from '../../assets/newImportList/icon-search.png';
import SimilarProductTable from './components/SimilarProductTable';
import {
  postPageLoadCustomEvent,
  postSearchResultEvent,
  removeImgParams,
  testUrl
} from './util';
import SearchInput from './components/SearchInput';
import ProcessBar from './components/ProcessBar';
import iconShop from '../../assets/myproduct/icon_shop.png';
import { initOssClient } from 'common/initOssClient';

const fetchShip = async (list, shipFrom = '', shipTo = '', aeInfo) => {
  // console.log(aeInfo)
  const productIds = list.map(item => item.id);
  const shipInfo = await Post('/ali_similar_products/logistics/list', {
    data: {
      product_ids: productIds,
      send_goods_country: shipFrom,
      receive_goods_country: shipTo,
      ali_user_id: parseInt(aeInfo?.ali_user_id) || 0
    },
    cancelMessage: true
  });
  return shipInfo?.data?.logistics;
};

const formatShipInfo = shipInfo => {
  const shipMethodsTempMap = {};
  const shipMap = {};
  const shipMethods = [];
  Object.keys(shipInfo).forEach(productId => {
    const productShips = shipInfo[productId]?.content || [];
    const productShipMap = {};
    productShips.forEach(shipItem => {
      if (!shipMethodsTempMap[shipItem.company]) {
        shipMethodsTempMap[shipItem.company] = true;
        shipMethods.push(shipItem.company);
      }
      productShipMap[shipItem.company] = shipItem;
    });
    shipMap[productId] = productShipMap;
  });
  return {
    shipMap,
    shipMethods
  };
};

const getDeliveryFormatedTime = shipItem => {
  const { displayType } = shipItem;
  let displayTime;
  let sortTime;
  try {
    switch (displayType) {
      case 'deliveryDate':
      case 'limitDate':
        displayTime = shipItem.deliveryDateFormat;
        sortTime =
          (new Date(shipItem.deliveryDate) - Date.now()) / 1000 / 86400;
        break;
      default:
        displayTime = `${shipItem.time} days`;
        sortTime = shipItem.time?.split('-')?.[1] || Infinity;
        break;
    }
  } catch (error) {
    console.log(error);
  }
  return {
    displayTime: displayTime || '-',
    sortTime: sortTime || Infinity
  };
};

const createProductsWithShipInfo = (
  products,
  shipMap,
  selectedShip,
  shipMethods,
  currencyState
) => {
  if (!shipMethods.length) {
    return products;
  }
  return products.map(item => {
    const productId = item.id;
    const shipItem = shipMap?.[productId]?.[selectedShip];
    if (shipItem) {
      const { displayTime, sortTime } = getDeliveryFormatedTime(shipItem);
      const currency_ship_price = (Number(shipItem.freightAmount?.value) / currencyState[shipItem.freightAmount?.currency]?.rates_value).toFixed(2)
      return {
        ...item,
        ship_time: displayTime,
        sortTime,
        ship_price: currency_ship_price || shipItem.freightAmount?.formatedAmount || '-',
        sortShipPrice: shipItem.freightAmount?.value
      };
    }
    return item;
  });
};

function SupplierOptimizer({ location, aeInfo, userInfo, dispatch, currencyState }) {
  // 搜索到的源数据
  const [products, setProducts] = useState([]);
  // 物流map
  const [shipMap, setShipMap] = useState({});
  // 搜索到的商品的物流方式集合
  const [shipMethods, setShipMethods] = useState([]);
  // 用户选择的物流方式 默认使用速卖通标准物流
  const [selectedShip, setSelectedShip] = useState(
    'AliExpress Standard Shipping'
  );
  // 是否开启物流信息
  const [withShipInfo, setWithShipInfo] = useState(true);
  // 发货地 默认CN
  const [shipFrom, setShipFrom] = useState('CN');
  // 收获地 默认US
  const [shipTo, setShipTo] = useState('US');
  // 上传图片弹框visibility
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [bannerList, setBannerList] = useState([]);
  const [Echo, setEcho] = useState(false);
  const initParamsRef = useRef({});
  const [searchImage , setSearchImage] = useState(null)
  const newSelect = useRef(null)

  const [isShowSearch,setIsShowSearch] = useState(false)

  const [selectType,setSelectType] = useState('AliExpress')

  // 三种状态文字提示 'init'/'notFound'/'' - 初始提示/搜索结果为空/搜索结果不为空
  const [searchStatus, setSearchStatus] = useState('init');

  const searchType = useRef('AliExpress')
  // 增加了物流信息的商品数据
  const productsWithShipInfo = useMemo(() => {
    return createProductsWithShipInfo(
      products,
      shipMap,
      selectedShip,
      shipMethods,
      currencyState
    );
  }, [products, shipMap, selectedShip, shipMethods]);

  const [showSearchTip, setShowSearchTip] = useState(false);
  const [showGuideButton, setShowGuideButton] = useState(false);

  const getBannerList = async () => {
    const stores = userInfo.stores || [];
    const storeIds = stores.map(item => {
      return item?.id;
    });
    const params = {
      data: {
        store_ids: storeIds
      }
    };

    const res = await Get('/prod/propool/ad/list', params);
    if (res && res.code === 2000) {
      setBannerList(res.data);
    }
  };

  useEffect(() => {
    const { user_id } = JSON.parse(localStorage.getItem('store'));

    CommonDataCollect({
      user_id: user_id,
      // 店铺ID
      store_id: '',
      // 店铺名称
      shop_name: '',
      // 销售平台 shopify  wix  woocommerce
      sale_platform: 'shopify',
      // 销售平台对应商品ID
      sale_platform_product_id: '',
      // 销售平台对应订单ID
      sale_platform_order_id: '',
      // 供应商平台  aliexpress
      supply_platform: '',
      // 供应商平台商品ID
      supply_platform_product_id: '',
      // 供应商平台订单ID
      supply_platform_order_id: '',
      // 操作来源 如dsers
      trans_from: '',
      // 操作目的 如shopify
      trans_to: '',
      // 操作如 import_product
      action: 'open_supplier_optimizer',
      // 表名第二段 模块名称 如: product
      event_type: 'subscription_analysis'
    });
    getBannerList();
  }, []);

  useEffect(() => {
    if (userInfo) {
      const { status, created_at } = userInfo;
      const date = new Date(2021, 2, 24).getTime();
      if (`${status}`.substring(1, 2) === '1') {
        // 用户已看完新手引导
        if (
          localStorage.getItem('newOptimizeUser') !== '1' &&
          created_at * 1000 > date
        ) {
          localStorage.setItem('newOptimizeUser', 0);
        }
      }
    }
  }, [userInfo]);

  // 修改了物流选项 需重新加载物流
  const hanldeShipConfigChanged = useCallback(
    async (checked, from, to) => {
      if (checked && products.length) {
        setLoading(true);
        const logistics = await fetchShip(products, from, to, aeInfo);
        if (logistics) {
          const formatedShipObj = formatShipInfo(logistics);
          setShipMap(formatedShipObj.shipMap);
          setShipMethods(formatedShipObj.shipMethods);
        }
        setLoading(false);
      }
    },
    [products]
  );
  const handleShipChecked = useCallback(
    checked => {
      setWithShipInfo(checked);
      hanldeShipConfigChanged(checked, shipFrom, shipTo);
    },
    [hanldeShipConfigChanged, shipFrom, shipTo]
  );

  const handleShipFromChange = useCallback(
    value => {
      setShipFrom(value);
      hanldeShipConfigChanged(withShipInfo, value, shipTo);
      window.dataLayer.push({
        event: 'supplierOptimizerEvent',
        category: 'shipFromSelected'
      });
    },
    [hanldeShipConfigChanged, shipTo, withShipInfo]
  );

  const handleShipToChange = useCallback(
    value => {
      setShipTo(value);
      hanldeShipConfigChanged(withShipInfo, shipFrom, value);
      window.dataLayer.push({
        event: 'supplierOptimizerEvent',
        category: 'shipToSelected'
      });
    },
    [hanldeShipConfigChanged, shipFrom, withShipInfo]
  );

  const handleSearched = useCallback((params = {}) => {
    const {
      searchResult,
      errMessage,
      inputUrl,
      imgUrl,
      imgCode,
      resCategory,
      scene,
      ref,
      refAction,
      token
    } = params;
    postSearchResultEvent({
      searchResult,
      errMessage,
      inputUrl,
      imgUrl,
      imgCode,
      scene,
      ref,
      resCategory,
      refAction,
      token
    });
  }, []);
  // url搜相似
  const handleSearch = useCallback(
    async value => {
      if(value){
        setEcho(true)
      }else{
        setEcho(false)
      }
      if (!value || loading) {
        return false;
      }
      if(value.includes('/tmallproduct')){
        message.error('The Tmall product URL you entered is not supported for Supplier Optimiazer right now');
        return
      }
      searchType.current = 'AliExpress'
      setUrl(value.trim());
      if (!testUrl(value)) {
        if (value.trim().length !== 0) {
          message.error(intl.get('supplier_optimizer.url_error'));
        }
        return false;
      }
      setLoading(true);
      const initParams = initParamsRef.current;
      const { refId, id, url, token } = initParams;
      let res;
      let result;
      const arr = value.match(/\/(\d+).html/);
      let selfOrder = arr && arr[1];
      if (!selfOrder) {
        if (url && value === url) {
          selfOrder = refId || id;
        }
      }
      // 数据上报token 由商品池带过来的参数
      let stascticToken = '';
      if (selfOrder === refId || selfOrder === id) {
        stascticToken = token;
      }

      try {
        res = await Post('/ali_similar_products/product/search_by_url', {
          data: { url: removeImgParams(value) },
          cancelMessage: true
        });
        const errorMap = {
          5000: intl.get('supplier_optimizer.sys_error'),
          4000: intl.get('supplier_optimizer.url_error')
        };
        if ([4000, 5000].includes(res.code)) {
          result = 3;
          if(res?.msg?.includes('go-ali-similar-products.url_invalid')){
            message.error(intl.get('supplier_optimizer.url_error'))
            return
          }
          if(res?.msg?.includes('search product is empty')){
            message.error('AliExpress is facing some issues, please try again later.');
          }else{
            message.error(errorMap[res.code]);
          }
        } else if (res.data?.content?.length) {
          result = 1;
          const { search = '' } = location;
          const query = qs.parse(search.slice(1));
          if (localStorage.getItem('newOptimizeUser') === '0' && !query.url) {
            localStorage.setItem('newOptimizeUser', 1);
            setShowGuideButton(false);
          }
        } else {
          result = 2;
        }
      } catch (error) {
        console.log(error);
      }
      const msg = res?.msg || '';
      const data = res?.data || {};
      const list = data?.content || [];
      if (selfOrder) {
        list.forEach((item, index) => {
          if (stascticToken) {
            list[index].token = stascticToken;
          }
          if (list[index].id == selfOrder) {
            list[index].isSelf = true;
            list.unshift(list.splice(index, 1)[0]);
          }
        });
      }
      const imgObj = data.upload_image || {};
      const category = data.categories?.[0]?.name;
      let ref = 'similar';
      // console.log(initParams);
      let refAction = '';

      if (initParams.url && initParams.ref && value === initParams.url) {
        ref = initParams.ref;
        refAction = initParams.refAction;
      }
      // searchResult, errMessage, inputUrl, imgUrl, imgCode, resCategory, scene, ref, refAction, token
      handleSearched({
        searchResult: result,
        errMessage: msg,
        inputUrl: value,
        imgUrl: imgObj.url,
        imgCode: imgObj.code,
        resCategory: category,
        scene: 'dsers_page',
        ref,
        refAction,
        token: stascticToken
      });
      if (withShipInfo && list.length) {
        const logistics = await fetchShip(list, shipFrom, shipTo, aeInfo);
        if (logistics) {
          const formatedShipObj = formatShipInfo(logistics);
          setShipMap(formatedShipObj.shipMap);
          setShipMethods(formatedShipObj.shipMethods);
        }
        // list = await fetchAndAttachList(list, shipFrom, shipTo);
      }
      setProducts(list);
      if (!list.length) {
        setSearchStatus('notFound');
      } else {
        setSearchStatus('');
      }
      setLoading(false);
      return false;
    },
    [handleSearched, shipFrom, shipTo, withShipInfo, loading]
  );

  const handleSearchTmall = async (value) => {
    if (!value || loading) {
      return false;
    }
    if(!value.includes('/tmallproduct')){
      message.error('The URL you entered is not a Tmall URL, please check and re-enter.');
      return
    }
    searchType.current = 'Tmall'
    setUrl(value.trim());
    if (!testUrl(value)) {
      if (value.trim().length !== 0) {
        message.error(intl.get('supplier_optimizer.url_error'));
      }
      setLoading(false)
      return false;
    }
    setLoading(true);
    try {
      const res = await Get(`${dsersBFFApiHost}/dsers-product-bff/supplier-optimizer/list`,{
        data:{
          appId:"159831084",
          url:value
        },
        isCustomApi:true
      })
      setLoading(false)
      if(res.data.length){
        setProducts(res.data);
      }
      if (!res.data.length) {
        setSearchStatus('notFound');
      } else {
        setSearchStatus('');
      }
    } catch (error) {
      setSearchStatus('notFound');
      console.log(error);
    }
  }

  async function setGuideUrl() {
    let id;
    const params = {
      current: 1,
      page_size: 20,
      lang: getLanguage().replace('-', '_')
    };
    const res = await Get(
      `/onedata_propool/product_pool/search?${qs.stringify(params)}`
    );
    if (res && res.code === 2000) {
      if (res.data.content?.length > 0) {
        const target = res.data.content[0];
        id = target.id;
        const targetUrl = `https://www.aliexpress.com/item/${id}.html`;
        setUrl(targetUrl);
      }
    }
    return `https://www.aliexpress.com/item/${id}.html` || '';
  }
  useEffect(()=>{
    const { search = '' } = location;
    const query = qs.parse(search.slice(1));
    if (query.url){
      localStorage.setItem('storeId',query.store_id)
    }
  })
  // 进入页面时截取url参数并触发搜索
  useEffect(() => {
    const showGuide =
      window.OPTIMIZE_GUIDE && localStorage.getItem('newOptimizeUser') === '0';
    const { search = '' } = location;
    const query = qs.parse(search.slice(1));
    console.log(query);
    if (!query.url && showGuide) {
      setGuideUrl();
      setShowGuideButton(true);
    }
    if(query.url && query.supplierType === 'unmap'){
      searchType.current = 'AliExpress'
      handleSearch(query.url)
      setSelectType('AliExpress')
      return
    }
    if(query.url && query.supplierType === 'tmall'){
      searchType.current = 'Tmall'
      handleSearchTmall(query.url)
      setSelectType('Tmall')
      return
    }
    if (query.url) {
      searchType.current = 'AliExpress'
      setUrl(query.url);
      initParamsRef.current = {
        url: query.url,
        ref: query.ref,
        refAction: query.refAction,
        refId: query.refId,
        id: query.id,
        token: query.token
      };
      // 上线弹框携带cancelSearch参数跳转此页 此时不需要自动触发搜素并显示搜素tip
      setShowGuideButton(false);
      if (!query.cancelSearch) {
        handleSearch(query.url);
      } else {
        setShowSearchTip(true);
      }
    }
    postPageLoadCustomEvent({ token: initParamsRef.current?.token });
  }, [location]);

  const onCancelUpload = useCallback(() => {
    setUploadModalVisible(false);
  }, []);
  // 图片上传
  const onUploadImage = useCallback(
    async file => {
      if (!file) {
        return false;
      }
      setSearchImage(file)
      if(searchType.current === 'Tmall'){
        searchType.current = 'Tmall'
        upLoadImageTmall(file)
        return
      }
      searchType.current = 'AliExpress'
      setUploadModalVisible(false);
      setLoading(true);
      const data = new FormData();
      data.append('file', file);
      try {
        const res = await Post('/ali_similar_products/product/search_by_file', {
          data,
          cancelMessage: true
        });
        let result;
        if (res.code === 5000) {
          result = 3;
          if(res?.msg?.includes('search product is empty')){
            message.error('AliExpress is facing some issues, please try again later.');
          }else{
            message.error(intl.get('supplier_optimizer.sys_error'));
          }
        }
        if (res.code === 4000) {
          result = 3;
        } else if (res.data?.content?.length) {
          result = 1;
          if (localStorage.getItem('newOptimizeUser') === '0') {
            localStorage.setItem('newOptimizeUser', 1);
            setShowGuideButton(false);
          }
        } else {
          result = 2;
        }
        const msg = res?.msg || '';
        const resData = res?.data || {};
        const list = resData.content || [];
        const imgObj = resData.upload_image || {};
        const category = resData.categories?.[0]?.name;
        handleSearched(
          result,
          msg,
          '',
          imgObj.url,
          imgObj.code,
          category,
          'dsers_page',
          'similar',
          ''
        );
        if (withShipInfo && list.length) {
          const logistics = await fetchShip(list, shipFrom, shipTo, aeInfo);
          if (logistics) {
            const formatedShipObj = formatShipInfo(logistics);
            setShipMap(formatedShipObj.shipMap);
            setShipMethods(formatedShipObj.shipMethods);
          }
        }
        setProducts(list);
        if (!list.length) {
          setSearchStatus('notFound');
        } else {
          setSearchStatus('');
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
      return false;
    },
    [shipFrom, shipTo, withShipInfo, handleSearched,selectType]
  );

  const upLoadImageTmall = async (file) =>{
    setUploadModalVisible(false)
    setLoading(true)
    const time = JSON.stringify(new Date().valueOf())
    const name = time.substring(time.length - 6,time.length)
    try {
      const client = await initOssClient()
      const res = await client.put(name,file)
      if(res){
        const listRes = await Get(`${dsersBFFApiHost}/dsers-product-bff/supplier-optimizer/list`,{
          data:{
            appId:"159831084",
            imgUrl:res.url
          },
          isCustomApi:true
        })
        setLoading(false)
        if(listRes.data.length){
          setProducts(listRes.data);
        }
        if (!listRes.data.length) {
          setSearchStatus('notFound');
        } else {
          setSearchStatus('');
        }
      }
    } catch (error) {
      setLoading(false)
      setSearchStatus('notFound');
      console.log(error)
    }

  }

  const handleUnmapSearch = async (value) =>{
    if (!value || loading) {
      return false;
    }
    if (!testUrl(value)) {
      if (value.trim().length !== 0) {
        message.error(intl.get('supplier_optimizer.url_error'));
      }
      setLoading(false)
      return false;
    }
    setLoading(true);
    try {
        const res = await Get(`${dsersBFFApiHost}/dsers-product-bff/supplier-optimizer/list`,{
          data:{
            appId:"159831084",
            imgUrl: value
          },
          isCustomApi:true
        })
        setLoading(false)
        if(res.data.length){
          setProducts(res.data);
        }
        if (!res.data.length) {
          setSearchStatus('notFound');
        } else {
          setSearchStatus('');
        }
    } catch (error) {
      setLoading(false)
      setSearchStatus('notFound');
      console.log(error)
    }
  }

  const openUploadModal = useCallback(() => {
    setUploadModalVisible(true);
  }, []);

  const handleInputChange = useCallback(e => {
    if (e === '') {
      setUrl('');
      return;
    }
    setUrl(e.target.value);
  }, []);

  const handleSelectChange = (v) =>{
    setSelectType(v)
    searchType.current = v
    if(searchImage){
      onUploadImage(searchImage)
    } 
  }
  // 点击广告卡片的switch按钮
  const handleBannerClick = value => {
    dispatch({
      type: 'global/showOneClickMapping',
      payload: value,
      callback: d => {
        if (d.code == 2000) {
          getBannerList();
        }
      }
    });

    dispatch({
      type: 'global/dataTrack',
      payload: {
        data: {
          user_id: userInfo.user_id,
          store_id: value.store_id.toString(),
          platform: 'shopify',
          page: 'Supplier Optimizer',
          page_url: window.location.href,
          module: 'Supplier Optimizer',
          action: 'switch-button-click',
          product_id_list: [''],
          order_id_list: [''],
          custom_info: [
            {
              name: 'switch_version',
              value: getVersion(userInfo.user_id)
            },
            {
              name: 'ae_product_id',
              value: value.ae_product_id.toString()
            },
            {
              name: 'dsers_product_id',
              value: value.dsers_product_id.toString()
            },
            {
              name: 'dsers_product_id',
              value: value.dsers_product_id.toString()
            },
            {
              name: 'shopify_product_id',
              value: value.shopify_product_id.toString()
            },
            {
              name: 'mapping_type',
              value: value.mapping_type.toString()
            }
          ]
        }
      }
    });
  };

  const controlSearchhandler = useCallback(()=>{
    setIsShowSearch(true)
  })
  const changeShowSearchStatus = useCallback(()=>{
    setIsShowSearch(false)
  })

  const userId = userInfo.user_id;
  const isBannerUser = getVersion(userId);

  return (
    <div className={styles.findProductContainer}>
      <div className={styles.title} id="supplier-optimizer-scroll-top">
        <h1>{intl.get('supplier_optimizer.title')}</h1>
        {/* {!isShowSearch ? (
          <div className={styles.search} style={{position:'relative'}}>
            <Button onClick={controlSearchhandler}>
              <img src={searchIcon} alt="" />
              <span
                className={styles.span}
                style={{ width: url && Echo ? '125px' : '' }}
              >
                {url && Echo
                  ? url
                  : intl.get('supplier_optimizer.search_product')}
              </span>
            </Button>
            <InputGuide></InputGuide>
          </div>
        ) : null} */}
      </div>
      <p className={styles.contact}>
        {intl.getHTML('supplier_optimizer.intro')}
      </p>
      <div style={{ position: 'relative' }}>
        <ProcessBar loading={loading} />
      </div>
      <Spin spinning={loading} indicator={<span />}>
        {true ? (
          <SearchInput
            onSearch={handleSearch}
            onSearchTmall={handleSearchTmall}
            value={url}
            show={isShowSearch}
            changeShowSearchStatus={changeShowSearchStatus}
            handleInputChange={handleInputChange}
            openUploadModal={openUploadModal}
            showSearchTip={showSearchTip}
            showGuideButton={showGuideButton}
            selectType={selectType}
            handleSelectChange={handleSelectChange}
          />
        ) : null}
        <UploadImageModal
          visible={uploadModalVisible}
          onCancel={onCancelUpload}
          onOk={onUploadImage}
          selectType={selectType}

        />
        {
          searchType.current === 'Tmall' ? <div style={{marginTop:'24px'}}></div> :
            <ShippingSelect
              checked={withShipInfo}
              onCheck={handleShipChecked}
              shipFromChange={handleShipFromChange}
              shipToChange={handleShipToChange}
              shipMethods={shipMethods}
              shipMethodChange={setSelectedShip}
              hidden={searchStatus !== ''}
            />
        }
        <SimilarProductTable
          hidden={searchStatus !== ''}
          products={productsWithShipInfo}
          searchType={searchType}
        />

        {searchStatus === 'init' ? (
          <div className={styles.initTipBox}>
            {bannerList.length && isBannerUser !== 'notBannerUser' ? (
              <BannerTable
                bannerList={bannerList}
                userId={userId}
                handleBannerClick={handleBannerClick}
              />
            ) : (
              <div className={styles.noData}>
                <div className={styles.description}>
                  <span className={styles.title}>{intl.getHTML('supplier_optimizer.optimizerTitle')}</span>
                  <span className={styles.text}>{intl.getHTML('supplier_optimizer.optimizerDesc_1')}</span>
                  <span className={styles.text}>{intl.getHTML('supplier_optimizer.optimizerDesc_2')}</span>
                  <span style={{color:'rgba(0,0,0,0.5)',fontSize:'12px'}}>{intl.get('supplier_optimizer.msg')}</span>
                </div>
                <img src={findProductImg} alt="" />
              </div>
            )}
          </div>
        ) : null}
        {searchStatus === 'notFound' ? (
          <div className={styles.notFoundTipBox}>
            <img src={iconShop} />
            {intl.getHTML('supplier_optimizer.noSearch')}
          </div>
        ) : null}
      </Spin>
      <div id="supplier-optimizer-scroll-bottom" />
      <ScrollControl
        visible={!!productsWithShipInfo.length}
        topId="supplier-optimizer-scroll-top"
        bottomId="supplier-optimizer-scroll-bottom"
        className={styles.scrollControl}
      />
    </div>
  );
}

const mapStateToProps = ({ global, login }) => {
  return {
    aeInfo: global.AEInfo,
    userInfo: login.userInfo,
    currencyState: global.currencyState
  };
};

export default withRouter(
  connect(mapStateToProps)(React.memo(SupplierOptimizer))
);
